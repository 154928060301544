import React, {useEffect, useState, useContext} from "react"
import Seo from "../components/utilities/Seo"
import {AnimatePresence, motion, useAnimation , usePresence} from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {SectionNavContext} from '../components/utilities/SiteContexts'
import {ourProjectsHolder} from './pages.module.scss'
import RisingSpans from "../components/components/RisingSpans"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ContactSection from "../components/components/ContactSection"



const pageVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type:'tween',
      duration:0.5
    }
  }
}

const projectVariants = {
  pre: {
    opacity:0,
    y:'2rem'
  },
  vis: {
    opacity:1,
    y:'0rem',
    transition: {
      type:'tween',
      duration:1
    }
  },
  post: {
    opacity:0,
    y:'-2rem',
    transition: {
      type:'tween',
      duration:0.5
    }
  }
}

const sunVariants = {
  pre: {
    pathLength: 0
  },
  vis: {
    pathLength: 1,
    transition:{
      duration:0.8
    }
  },
  post: {
    pathLength: 0,
    transition:{
      duration:0.5
    }
  }
}

const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    htmlFontSize: 10,
    fontFamily: ['Switzer']
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#44A572',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  shape: {
    borderRadius:0
  },
  shadows: ['none'],
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor:'#ffffff',
        marginBottom: '1.5rem',
        '&$focused': {
          backgroundColor:'#ffffff'
        },
        '&:hover': {
          backgroundColor:'#fafafa'
        },
        '&$error': {
          marginBottom:'0.5rem'
        },
        helperText: {
          marginBottom:'1rem'
        }
      }
    },
    MuiButton: {
      containedSizeLarge: {
        paddingLeft: '3rem',
        paddingRight: '3rem',
        fontSize:'1.8rem'
      }
    },
    MuiFormHelperText: {
      root: {
        marginBottom:'1rem'
      }
    }
  }
});



const Project = ({project})=> {
  const image = getImage(project.featuredImage?.node.localFile)
  return(
    <motion.li className="project" variants={projectVariants} initial="pre" animate="vis" exit="post">
      <div className="projectImage">{ image && <GatsbyImage image={image} alt={project.title} /> }</div>
      <div className="info">
        <h3>{project.title}</h3>
        <div className="text" dangerouslySetInnerHTML={{__html: project.content}} />
      </div>
    </motion.li>
  )
}

const FeaturedProject = ({project, projectNumber, sunControl}) => {

  const image = getImage(project.featuredImage?.node.localFile)

  return (
    <div className="featuredProject">
      <h3 className="projectNumber">Case Study {projectNumber}</h3>
      <div className="projectImage">{ image && <GatsbyImage className="featuredImage" image={image} alt={project.title} /> }</div>
      <div className="inner">
        <h2 className="title">{project.title}</h2>
        <div className="text" dangerouslySetInnerHTML={{__html: project.content}} />
      </div>
      <motion.div className="sun">
        <svg className="dark" width="407" height="407" viewBox="0 0 407 407" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path animate={sunControl} initial="pre" variants={sunVariants} d="M405.5 203.5C405.5 315.062 315.062 405.5 203.5 405.5C91.9385 405.5 1.5 315.062 1.5 203.5C1.5 91.9385 91.9385 1.5 203.5 1.5C315.062 1.5 405.5 91.9385 405.5 203.5Z" stroke="currentColor" stroke-width="3"/>
        </svg>
      </motion.div>
    </div>
  )
}



const OurProjectsPage = () => {
  const [category, setCategory] = useState('')
  const [categories, setCategories] = useState([])
  const pageControl = useAnimation()
  const sunControl = useAnimation()
  const [isPresent, safeToRemove] = usePresence()
  const [, setSectionNav] = useContext(SectionNavContext)


  const data = useStaticQuery(graphql`
  query MyQuery {
    allWpProject {
      nodes {
        title
        menuOrder
        slug
        content
        terms {
          nodes {
            name
          }
        }      
        featuredProject {
          featuredProject
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 980
                  height: 600
                  placeholder: BLURRED
                  layout:CONSTRAINED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
  `)

  useEffect(() => {
    const introAnimation = async ()=> {
      await pageControl.start('vis')
      sunControl.start('vis')

    }

    introAnimation()

    setSectionNav([])
  }, [])

  useEffect( ()=>{

    const exitAnimation = async () => {
     sunControl.start('post')
      await pageControl.start('post')
      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation()
    }
  },[pageControl, isPresent, safeToRemove, sunControl])

  useEffect(()=>{
    let categories = []

    if(data?.allWpProject?.nodes.length>0) {

    const projects = data?.allWpProject?.nodes

    projects.forEach(project => {

      if(project?.terms?.nodes?.length > 0)
        project.terms.nodes.forEach(term => {
           categories.push(term.name)      
        });
      
    });

    let uniqueCategories = [...new Set(categories)];

    setCategories(uniqueCategories)

    }

  }, [data])

  const unfeaturedProjects = data.allWpProject.nodes.filter(project=> !project.featuredProject.featuredProject)
  const featuredProjects = data.allWpProject.nodes.filter(project=> project.featuredProject.featuredProject)


  return (
    <motion.article className={ourProjectsHolder}  variants={pageVariants} animate={pageControl} initial="pre">
      <Seo title="Our Projects" description="Find out more about some previous Carbon Climate Solutions projects" />
      <section className="introSection">
        <RisingSpans staggerChildren={0.3}><h1>Our Projects</h1></RisingSpans>
        <h4 className="subtitle">Read more about a few of our previous or in-development projects.</h4>
        {/* <ul className="buttons">
          <li><a href="#case-studies" title="Skip to Case Studies">Case Studies</a></li>
          <li><a href="#all-projects" title="Skip to All Projects">All Projects</a></li>
        </ul> */}
      </section>

      {
      // featuredProjects.length>0 && <section className="featuredProjectsSection" id="case-studies">
      //   <ul>
      //   {
      //     featuredProjects.map((featuredProject, index) => {
      //       return <li className="projectHolder"><FeaturedProject sunControl={sunControl} project={featuredProject} projectNumber={index+1}/></li>
      //     })
      //   }
      //   </ul>
      // </section>
      }
      
      <section className="projectsSection" id="all-projects">
      <ThemeProvider theme={theme}>

      <FormControl variant='outlined' className="selectHolder" >
        <InputLabel>Filter Projects</InputLabel>
        <Select
          value={category}
          label="Filter Projects"
          onChange={(e)=>setCategory(e.target.value)}
        >
          <MenuItem value={''}>All</MenuItem>
          {
            categories.map(category => {
            return <MenuItem key={category} value={category}>{category}</MenuItem>
            }
            )
          }
        </Select>
      </FormControl>

      </ThemeProvider>
      

      <motion.ul className="projects">
        <AnimatePresence>
        {
          unfeaturedProjects.filter(project=> {
            
            const checkCategory = (project, category) => {
              let categoryFound = false
              
              if(project?.terms?.nodes?.length > 0)
                project.terms.nodes.forEach(term => {
                  if(term.name.toLowerCase() === category.toLowerCase())
                  categoryFound = true                
              });

              return categoryFound
            }

            return category ?
            checkCategory(project, category)
            :  project 
          })
            .map(project => <Project key={project.slug} project={project} />)
        }
        </AnimatePresence>
      </motion.ul>
      </section>

      <ContactSection isDark={false} title={"Would you like to hear more?"} subTitle="Please get in touch."/>

    </motion.article>
  )
}

export default OurProjectsPage
